@media print {
  .report-page,
  .app-root {
    display: none !important;
  }

  @page {
    size: landscape;
  }

  .report-print-table {
    width: 100%;
  }

  td,
  th {
    font-size: 0.4rem;
  }

  td {
    font-weight: 500;
    line-height: 0.4rem;
  }

  .report-print-wrapper {
    width: 100%;
  }

  h1.report-print-header {
    width: 100%;
    font-size: 1.25rem;
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 400;
  }

  .report-print-sub-headers-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      font-size: 0.8rem;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }
  }

  .report-print-sub-headers-wrapper {
    border-bottom: 1px solid #eee;
  }
}
