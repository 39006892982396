@import "./base-tailwind.scss";

@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

html {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  background-color: white;
}

body {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

#header {
  box-shadow: 0 3px 3px #aaa;
  background: #fff;
  background: linear-gradient(to right, #fff 0, #e7eef2 50%, #d0dde5 100%);
}
